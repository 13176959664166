import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from 'emotion'
import { get } from 'lodash'
import { Layout as _Layout, Text } from '../constants'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { splitParagraphs } from '../helpers'

const styles = {
  container: {
    maxWidth: 900,
    padding: _Layout.EDGE_PADDING,
    paddingBottom: _Layout.EDGE_PADDING * 2,
    margin: '0 auto',
    marginTop: 60,
  },
  image: {
    maxWidth: 600,
  },
  imageContainer: {
    paddingBottom: _Layout.EDGE_PADDING,
    paddingTop: _Layout.EDGE_PADDING,
  },
  title: {
    fontSize: Text.TITLE,
    fontWeight: 'bold',
  },
  header: {
    fontSize: Text.HEADER,
    fontWeight: 'bold',
    paddingBottom: _Layout.EDGE_PADDING,
  },
  description: {
    maxWidth: 600,
    paddingBottom: _Layout.EDGE_PADDING,
  },
  memberDescription: {
    paddingBottom: _Layout.EDGE_PADDING,
  },
}
export const AboutPageTemplate = ({
  title,
  description,
  team,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section className={css(styles.container)}>
      <h1 className="has-text-weight-bold is-size-2">{title}</h1>
      <p className={css(styles.description)}>{description}</p>
      <h3 className={css(styles.title)}>{team && team.heading}</h3>
      {team &&
        team.members &&
        team.members.map(({ heading, image, description }) => (
          <div key={heading}>
            <div className={css(styles.imageContainer)}>
              {image && (
                <PreviewCompatibleImage
                  style={styles.image}
                  imageInfo={image}
                />
              )}
            </div>
            <h4 className={css(styles.header)}>{heading}</h4>
            {splitParagraphs({
              data: description,
              style: css(styles.memberDescription),
            })}
          </div>
        ))}
      <PageContent className="content" content={content} />
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  team: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter, html },
    navbarData,
    footerData,
  } = data

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        description={frontmatter.description}
        team={frontmatter.team}
        content={html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        team {
          heading
          members {
            heading
            description
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
