import React from 'react'

const splitParagraphs = props => {
  const { data, style } = props

  if (data.includes('\n')) {
    return data.split('\n').map(paragraph => {
      return (
        <p key={Math.random()} className={style}>
          {paragraph}
        </p>
      )
    })
  }

  return (
    <p key={Math.random()} className={style}>
      {' '}
      {data}
    </p>
  )
}

export { splitParagraphs }
